@import '../../custom';

$graphicsSpace: 4rem;

.clr_v3_Header {
  box-shadow: none;
  z-index: 1021;
  &__outside_Speciality {
    @media (max-width: 399px) {
      .fs-5 {
        font-size: 2.6vw !important;
      }
    }
    a:hover {
      opacity: 0.8;
    }
  }

  &__speciality__btn {
    i {
      transform-style: preserve-3d;
      animation: flipIcon 1.5s ease-in-out infinite alternate;
    }
    // span {
    //   max-width: 12rem;
    // }
    @keyframes flipIcon {
      0% {
        transform: rotateY(180deg);
      }
      100% {
        transform: rotateY(0);
      }
    }
  }

  &__navbar {
    padding-bottom: $graphicsSpace;
  }

  &__svgShape {
    width: calc(100% + 1rem);
    margin: 0 -0.5rem -0.1rem;
    height: auto;
  }

  &__brand {
    width: 4.2rem;
    height: 4.2rem;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  &__right {
    ul {
      li {
        display: inline-block;

        a {
          color: $white;
          padding: 0.8rem;
          display: block;
        }
      }
    }
    .clr_v3_profileDropDown {
      &::after {
        display: none;
      }
    }
  }

  &__trendingContainer {
    margin-top: -$graphicsSpace;
  }

  &__trending {
    &__arrow {
      width: 4rem;
      height: 4rem;
    }
  }

  &__exploreDesktop {
    width: 760px;
    box-shadow: 0 0.5rem 0.8rem 0 rgba($black, 0.04);
    max-width: 100%;
    border-radius: 0 0 $border-radius-lg $border-radius-lg;
    visibility: hidden;
    opacity: 0;
    translate: 0 2rem;
    transition: all 0.03s ease-in-out;
    list-style-type:none ;
    .clr_v3_exploreDesktop__menuIcon {
      width: 5rem;
      height: 5rem;
      --bs-bg-opacity: 0.08;
      color: $black;
      img {
        width: 3.8rem;
        height: 2.8rem;
        filter: brightness(0);
      }
    }

    li a {
      --bs-bg-opacity: 0.03;

      &:hover,
      &.active {
        --bs-bg-opacity: 0.1;

        .clr_v3_exploreDesktop__menuIcon {
          --bs-bg-opacity: 1;
          color: $white;
          img {
            filter: brightness(0) invert(1);
          }
        }
      }
    }
  }

  &__menu-link {

    & > ul > li {
      & > a {
        color: $black;
        position: relative;
        min-height: 7.6rem;

        &::before {
          position: absolute;
          bottom: 0;
          width: 0;
          @include translateX;
          content: '';
          background-color: var(--bs-primary);
          height: 0.15rem;
        }
      }
      &:hover > a,
      &.active > a {
        color: var(--bs-primary);
        &::before {
          width: 100%;
        }
      }
      &:hover {
        .clr_v3_Header__exploreDesktop {
          visibility: visible;
          opacity: 1;
          translate: 0;
        }
      }
    }
  }

  //

  &--navOnly {
    padding-bottom: 0 !important;

    .clr_v3_Header__navbar {
      padding-bottom: 0 !important;

      .clr_v3_Header__brand {
        background-color: var(--bs-light);
      }

      .clr_v3_Header__nav {
        // padding: 1rem 0 !important;

        .clr_v3_Header__right ul li {
          a {
            color: $black !important;
            &:hover {
              color: var(--bs-primary) !important;
            }
          }

          .border-white {
            border-color: $black !important;
          }
        }
      }
    }

    .clr_v3_Header__trendingContainer,
    .clr_v3_Header__background,
    .clr_v3_Header__menuArea,
    .clr_v3_Header__speciality {
      display: none !important;
    }
  }

  &--desktop {
    box-shadow: none;

    .clr_v3_Header__right ul li a {
      color: $black;
      padding: 1.2rem 1rem;
      &:hover {
        color: var(--bs-primary);
      }
      
    }

    .clr_v3_Header__brand {
      width: 5.6rem;
      height: 5.6rem;
    }
    @include media-breakpoint-down(lg){
    .clr_v3_Header__menu-link{ position: absolute; top: 100%; width: 100%; left: 0; background-color: $white; height: auto !important;}
  }
  }

  //profile
  &--profile {
    .progress {
      height: 2px;
      margin-top: 1rem;
    }
  }
}

.mobile {
  .clr_v3_submenuTopbar {
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    box-shadow: 0rem 0rem 1rem 0 rgba($black, 0.05);
  }
}

.clr_v3_Header__menu {
  .swiper-button-prev,
  .swiper-button-next {
    @include translateY;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.2rem;
    height: 3.2rem;
    background-color: $white;
    border-radius: $border-radius-circle;
    background-color: $white;
    box-shadow: $box-shadow;
    margin: 0;
    &::after {
      font-size: 1.4rem;
      color: var(--bs-primary);
    }
    &.swiper-button-disabled {
      opacity: 0;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-button-next {
    right: 0;
  }

  &__contentCount {
    top: 0rem;
    opacity: 0;
    transition: $transition-base;

    &::before {
      position: absolute;
      top: calc(100% - 1px);
      translate: -50%;
      left: 50%;
      clip-path: polygon(50% 100%, 0 0, 100% 0);
      content: '';
      width: 1rem;
      height: 0.5rem;
      background-color: var(--bs-primary);
    }
  }

  &__item {
    color: $dark;
    padding: 1rem 1.6rem;
    border-radius: $border-radius-pill;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .pulseCircle {
      margin-right: 0.6rem;
    }

    &.active,
    &:hover {
      color: var(--bs-primary);
      background-color: var(--bs-light);

      .clr_v3_Header__menu__contentCount {
        opacity: 1;
        top: -1rem;
      }
    }
  }
}

.clr_v3_Header__submenu {
  .clr_v3_Header__menu__item {
    padding: 1.4rem 0.5rem;

    @include media-breakpoint-up(sm) {
      padding: 1.4rem 1.5rem;
    }
  }
}
