.loading-container {
  height: 100vh;
  /* padding: 1em; */
  text-align: center;
  background: #ffffff;
}
h3 {
  margin: 1em 0;
  font-weight: lighter;
  color: #00a1af;
  font-family: "Gill sans", sans-serif;
}
.svgcl {
  margin-top: 10em;
  border-style: double;
  border-radius: 50px;
  border-width: 10px;
  border-color: #00a1af transparent;
}
