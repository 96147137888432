@import '../../custom';

body {
    @include media-breakpoint-up(lg) {
        background-color: $white !important;
    }
}

.clr_v3_mainContent {
    @include media-breakpoint-up(lg) {
        background-color: var(--bs-body-bg);
        border-radius: $border-radius-xl;
    }

    &__width {
        max-width: 960px;
    }
}

.clr_v3_SpecialityLeft {
    &__profileContent {
        .progress {
            height: 3px;
        }

        .warning {
            .progress {
                background-color: rgba($warning, .15);
            }
        }

        .danger {
            .progress {
                background-color: rgba($danger, .15);
            }
        }

        .success {
            .progress {
                background-color: rgba($success, .15);
            }
        }

    }

    &__footer {
        &__list {
            li a:hover {
                color: var(--bs-primary) !important;
            }
        }

        &__social {
            color: $black;

            &:hover {
                background-color: var(--bs-primary) !important;
                color: $white !important;
            }
        }
    }

}