@import '../custom';
$icon-size: (
  //   "16": 1.6,
  "24": 2.4,
  '32': 3.2,
  "36": 3.6,
  //   "60": 6,
);

@each $key,
$val in $icon-size {
  @each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

      .icon-size#{$infix}-#{$key} {
        width: $val + rem;
        height: $val + rem;
      }
    }
  }
}