.clr_v3_swiper.swiper {
    .swiper-pagination {
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1.5rem;
        gap: .6rem;

        @include media-breakpoint-down(sm) {
            padding: 1rem;
        }

        .swiper-pagination-bullet {
            background-color: var(--bs-primary);
            --bs-bg-opacity: .15;
            border-radius: .6rem;
            margin: 0;
            width: 5px;
            height: 5px;
            transition: all .3s ease-in-out;

            &.swiper-pagination-bullet-active {
                width: 2rem;
            }
        }
    }
}