.clr_v3_loader_container { z-index: 1051;
  .DNA_cont {
    position: relative;
    transform: scale(0.3);
  }

  .nucleobase {
    display: inline-block;
    position: relative;
    vertical-align: middle;
  }

  .nucleobase:not(:last-child) {
    margin-right: 4.86vh;
  }

  .nucleobase:before,
  .nucleobase:after {
    content: '';
    display: inline-block;
    width: 3vh;
    height: 3vh;
    border-radius: 50%;
    position: absolute;
  }

  .nucleobase:nth-child(1) {
    -webkit-animation-delay: -1.869s;
    animation-delay: -1.869s;
  }

  .nucleobase:nth-child(1):before {
    -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -1.869s;
    animation-delay: -1.869s;
    background-color: var(--bs-primary);
  }

  .nucleobase:nth-child(1):after {
    -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -1.869s;
    animation-delay: -1.869s;
    background-color: rgba(var(--bs-primary-rgb), 0.25);
  }

  .nucleobase:nth-child(2) {
    -webkit-animation-delay: -3.738s;
    animation-delay: -3.738s;
  }

  .nucleobase:nth-child(2):before {
    -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -3.738s;
    animation-delay: -3.738s;
    background-color: var(--bs-primary);
  }

  .nucleobase:nth-child(2):after {
    -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -3.738s;
    animation-delay: -3.738s;
    background-color: rgba(var(--bs-primary-rgb), 0.25);
  }

  .nucleobase:nth-child(3) {
    -webkit-animation-delay: -5.607s;
    animation-delay: -5.607s;
  }

  .nucleobase:nth-child(3):before {
    -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -5.607s;
    animation-delay: -5.607s;
    background-color: var(--bs-primary);
  }

  .nucleobase:nth-child(3):after {
    -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -5.607s;
    animation-delay: -5.607s;
    background-color: rgba(var(--bs-primary-rgb), 0.25);
  }

  .nucleobase:nth-child(4) {
    -webkit-animation-delay: -7.476s;
    animation-delay: -7.476s;
  }

  .nucleobase:nth-child(4):before {
    -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -7.476s;
    animation-delay: -7.476s;
    background-color: var(--bs-primary);
  }

  .nucleobase:nth-child(4):after {
    -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -7.476s;
    animation-delay: -7.476s;
    background-color: rgba(var(--bs-primary-rgb), 0.25);
  }

  .nucleobase:nth-child(5) {
    -webkit-animation-delay: -9.345s;
    animation-delay: -9.345s;
  }

  .nucleobase:nth-child(5):before {
    -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -9.345s;
    animation-delay: -9.345s;
    background-color: var(--bs-primary);
  }

  .nucleobase:nth-child(5):after {
    -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -9.345s;
    animation-delay: -9.345s;
    background-color: rgba(var(--bs-primary-rgb), 0.25);
  }

  .nucleobase:nth-child(6) {
    -webkit-animation-delay: -11.214s;
    animation-delay: -11.214s;
  }

  .nucleobase:nth-child(6):before {
    -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -11.214s;
    animation-delay: -11.214s;
    background-color: var(--bs-primary);
  }

  .nucleobase:nth-child(6):after {
    -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -11.214s;
    animation-delay: -11.214s;
    background-color: rgba(var(--bs-primary-rgb), 0.25);
  }

  .nucleobase:nth-child(7) {
    -webkit-animation-delay: -13.083s;
    animation-delay: -13.083s;
  }

  .nucleobase:nth-child(7):before {
    -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -13.083s;
    animation-delay: -13.083s;
    background-color: var(--bs-primary);
  }

  .nucleobase:nth-child(7):after {
    -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -13.083s;
    animation-delay: -13.083s;
    background-color: rgba(var(--bs-primary-rgb), 0.25);
  }

  .nucleobase:nth-child(8) {
    -webkit-animation-delay: -14.952s;
    animation-delay: -14.952s;
  }

  .nucleobase:nth-child(8):before {
    -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -14.952s;
    animation-delay: -14.952s;
    background-color: var(--bs-primary);
  }

  .nucleobase:nth-child(8):after {
    -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -14.952s;
    animation-delay: -14.952s;
    background-color: rgba(var(--bs-primary-rgb), 0.25);
  }

  .nucleobase:nth-child(9) {
    -webkit-animation-delay: -16.821s;
    animation-delay: -16.821s;
  }

  .nucleobase:nth-child(9):before {
    -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -16.821s;
    animation-delay: -16.821s;
    background-color: var(--bs-primary);
  }

  .nucleobase:nth-child(9):after {
    -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -16.821s;
    animation-delay: -16.821s;
    background-color: rgba(var(--bs-primary-rgb), 0.25);
  }

  .nucleobase:nth-child(10) {
    -webkit-animation-delay: -18.69s;
    animation-delay: -18.69s;
  }

  .nucleobase:nth-child(10):before {
    -webkit-animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animBefore 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -18.69s;
    animation-delay: -18.69s;
    background-color: var(--bs-primary);
  }

  .nucleobase:nth-child(10):after {
    -webkit-animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    animation: animAfter 2.1s cubic-bezier(0.42, 0, 0.58, 1) infinite;
    -webkit-animation-delay: -18.69s;
    animation-delay: -18.69s;
    background-color: rgba(var(--bs-primary-rgb), 0.25);
  }

  @keyframes animBefore {
    0% {
      top: -6vh;
      z-index: 1;
    }

    25% {
      transform: scale(1.2);
      background-color: rgba(var(--bs-primary-rgb), 0.8);
      z-index: 1;
    }

    50% {
      top: 6vh;
      z-index: -1;
    }

    75% {
      background-color: rgba(var(--bs-primary-rgb), 0.25);

      transform: scale(0.8);
      z-index: -1;
    }

    100% {
      top: -6vh;
      z-index: -1;
    }
  }

  @keyframes animAfter {
    0% {
      top: 6vh;
      z-index: -1;
    }

    25% {
      background-color: rgba(var(--bs-primary-rgb), 0.9);

      transform: scale(0.8);
      z-index: -1;
    }

    50% {
      top: -6vh;
      z-index: 1;
    }

    75% {
      transform: scale(1.2);
      background-color: rgba(var(--bs-primary-rgb), 0.5);
      z-index: 1;
    }

    100% {
      top: 6vh;
      z-index: 1;
    }
  }

  .loading {
    &__letter {
      letter-spacing: 2px;
      animation-name: bounce;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      color: rgba(var(--bs-primary-rgb), 1);
    }

    .loading__letter:nth-child(2) {
      animation-delay: 0.1s;
    }
    .loading__letter:nth-child(3) {
      animation-delay: 0.2s;
    }
    .loading__letter:nth-child(4) {
      animation-delay: 0.3s;
    }
    .loading__letter:nth-child(5) {
      animation-delay: 0.4s;
    }
    .loading__letter:nth-child(6) {
      animation-delay: 0.5s;
    }
    .loading__letter:nth-child(7) {
      animation-delay: 0.6s;
    }
    .loading__letter:nth-child(8) {
      animation-delay: 0.8s;
    }
    .loading__letter:nth-child(9) {
      animation-delay: 1s;
    }
    .loading__letter:nth-child(10) {
      animation-delay: 1.2s;
    }

    @keyframes bounce {
      0% {
        // transform: translateY(0px);
        color: rgba(var(--bs-primary-rgb), 0.25);
      }
      50% {
        // transform: translateY(1rem);
        color: rgba(var(--bs-primary-rgb), 1);
      }
      100% {
        // transform: translateY(0px);
        color: rgba(var(--bs-primary-rgb), 0.25);
      }
    }
  }
}
