.hover-primary {
    transition: $transition-base;

    &:hover,
    &:active {
        color: var(--bs-primary) !important;
    }
}

.hover-opacity {
    transition: $transition-base;

    &:hover,
    &:active {
        opacity: 0.7;
    }
}

.hover-image {

    img {
        transition: $transition-base;
    }

    &:hover,
    &:active {
        img {
            transform: scale(1.08);
        }
    }
}


.rippleEffect {
    @include rippleEffect;

    &.ripple-primary::after {
        background: rgba(var(--bs-primary-rgb), .5);
    }

    &.ripple-secondary::after {
        background: rgba(var(--bs-secondary-rgb), .5);
    }

    &.ripple-success::after {
        background: rgba(var(--bs-success-rgb), .5);
    }

    &.ripple-danger::after {
        background: rgba(var(--bs-danger-rgb), .5);
    }

    &.ripple-warning::after {
        background: rgba(var(--bs-warning-rgb), .5);
    }

    &.ripple-info::after {
        background: rgba(var(--bs-info-rgb), .5);
    }

    &.ripple-light::after {
        background: rgba(var(--bs-light-rgb), .5);

    }
}

///pulsBlink
.pulseCircle {
    background: $danger;
    position: relative;
    height: 0.8rem;
    width: 0.8rem;
    animation: pulse-animation 1.5s infinite;
    border-radius: 50%;

    @keyframes pulse-animation {
        0% {
            box-shadow: 0 0 0 0px rgba($danger, 1);
            transform: scale(0.8);
        }

        100% {
            box-shadow: 0 0 0 0.5rem rgba($danger, 0);
            transform: scale(1);
        }
    }
}

///pulseBorder
.pulseBorder {
    background: var(--bs-primary);
    position: relative;
    height: 6.4rem;
    width: 6.4rem;
    border-radius: 50%;
    animation: pulsBorderMain 1s ease-out;
    animation-iteration-count: infinite;

    &::before,
    &::after {
        content: '';
        border-radius: 50%;
        position: absolute;
        animation-iteration-count: infinite;
        opacity: 0;
        z-index: 99;
    }

    &:before {
        left: -1.8rem;
        bottom: -1.8rem;
        height: 10rem;
        width: 10rem;
        border: 1px solid var(--bs-primary);
        animation: pulsBorder 1.6s ease-out;
        animation-iteration-count: infinite;
    }

    &:after {
        left: -1rem;
        bottom: -1rem;
        height: 8.4rem;
        width: 8.4rem;
        border: 1px solid var(--bs-primary);
        animation: pulsBorder 2s ease-out;
        animation-iteration-count: infinite;
    }

    @keyframes pulsBorderMain {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(0.9);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes pulsBorder {
        0% {
            transform: scale(0.6, 0.6);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            transform: scale(1, 1);
            opacity: 0;
        }
    }
}


//circleProgress
.circleProgress {
    width: 4rem;
    height: 4rem;

    .circleProgressIn {
        border-radius: 40%;
        animation: animate 6s linear infinite;
    }

    .circleProgressIn2 {
        border-radius: 44%;
        animation: animate 8s linear infinite;
    }
}

@keyframes animate {
    0% {
        transform: translateX(-50%) rotate(0deg);
    }

    100% {
        transform: translateX(-50%) rotate(360deg);
    }
}