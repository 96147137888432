@import '../../custom';

.clr_v3_mobileFooter {
  box-shadow: 0rem 0rem 1rem 0 rgba($black, 0.1);
  z-index: 1050;

  &__Nav-Circle {
    translate: -50% 0;
    width: 1.8rem;
    height: 1.8rem;
    top: -0.9rem;
    border: 0.35rem solid rgba($white, 0.68);
    opacity: 0;
    scale: 0;
  }

  .navbar-nav {
    .nav-link {
      padding: 1.8rem 0 0.6rem;

      &__icon {
        width: 2.4rem;
        height: 2rem;

        svg {
          width: 100%;
          height: 100%;
          fill: $black;
        }
      }
    }

    .active {
      .clr_v3_mobileFooter__Nav-Circle {
        scale: 1;
        opacity: 1;
      }
      .nav-link {
        color: var(--bs-primary);
      }
    }
    .clr_v3_mobileFooter__ExploreClick.active {
      .clr_v3_mobileFooter__Nav-Circle{ scale: 0; opacity: 0; }
      i {
        rotate: -45deg;
      }
    }

    @include media-breakpoint-up(sm) {
      .nav-link {
        padding: 2rem 0 1rem;

        &__icon {
          width: 2.6rem;
          height: 2.2rem;
        }
      }
    }
  }

  .clr_v3_explore {
    bottom: 100%;
    overflow-y: auto;
    transform: translateY(calc(100% + 6rem));

    &__bottomArea {
      background-color: $body-bg;
    }

    &__menus {
      &__icon {
        width: 5.2rem;
        height: 3.8rem;
        img {
          filter: brightness(0);
        }
      }

      &__number {
        &::after {
          position: absolute;
          background-color: $white;
          content: '';
          top: -1px;
          width: 100%;
          height: 1rem;
          left: 0;
          clip-path: polygon(20% 34%, 56% 98%, 70% 88%, 100% 0, 0 0, 0 100%);
        }
      }
      .active {
        & > div {
          background-color: var(--bs-primary);
          border-color: var(--bs-primary);
        }
        .clr_v3_explore__menus__icon {
          img {
            filter: brightness(0) invert(1);
          }
        }
        .text-black {
          color: $white !important;
        }
      }
    }
    &.active{
      transform: translateY(0);
    }
  }
}
