.clr_v3_borderGraphics {
    &.clr_v3_borderGraphics-big {
        height: calc(100% + 6rem);
    }

    &__line {
        display: none;

        @include media-breakpoint-down(sm) {
            width: 0.25rem;
            display: block;
        }

        &:first-child {
            border-bottom-left-radius: 0 !important;
            border-top-left-radius: 0 !important;
        }

        &:last-child {
            border-bottom-right-radius: 0 !important;
            border-top-right-radius: 0 !important;
        }
    }

    &__ttl_link:hover {
        opacity: 0.4;
    }
}

.clr_v3_cornerGraphicsDynamic {
    &>* {
        position: relative;
        z-index: 1;
    }

    &__Graphics {
        transition: $transition-base;

        @mixin graphics($size) {
            width: $size;
            height: $size;

            &.bordered {
                border-style: solid;
                border-width: calc((#{$size}*20) / 100);
            }

            &.top {
                top: calc(-#{$size}/2.5);
            }

            &.left {
                left: calc(-#{$size}/2.5);
            }

            &.right {
                right: calc(-#{$size}/2.5);
            }

            &.bottom {
                bottom: calc(-#{$size}/2.5);
            }



        }

        .graphics-size-sm {
            @include graphics(14rem);
        }

        .graphics-size-md {
            @include graphics(18rem);
        }

        .graphics-size-lg {
            @include graphics(24rem);
        }

    }
}

.clr_v3_TtlDivider {
    height: 1px;
    width: 25rem;
    max-width: 100%;

    &>span {
        max-width: 10rem
    }
}


// timeline
.clr_v3__timeline {
    width: 1px;
    top: 0.3rem;

    .rounded-circle {
        width: 11px;
        height: 11px;
        background-color: $body-bg;
    }

    .end {
        display: none;
        width: 2rem;
        height: 1px;
    }
}

.clr_v3__timelineParrent:last-child .end {
    display: block !important;
}