// ==================================colors
@import '../../../../node_modules/bootstrap/scss/functions';
// @import "./buttons";





// // ==================================colors theme
@mixin colorTheme($clr_primary: #355fd3,
  $clr_secondary: #22bc86,
  $clr_bodyBg: #f0f0f0,
  $clr_light: #edf0f7) {
  --bs-link-color: #{$clr_primary};
  --bs-link-color-rgb: #{to-rgb($clr_primary)};
  --bs-link-hover-color: #{$clr_primary};
  --bs-link-hover-color-rgb: #{to-rgb($clr_primary)};

  --bs-primary: #{$clr_primary};
  --bs-primary-text: #{$clr_primary};
  --bs-primary-rgb: #{to-rgb($clr_primary)};

  --bs-secondary: #{$clr_secondary};
  --bs-secondary-text: #{$clr_secondary};
  --bs-secondary-rgb: #{to-rgb($clr_secondary)};

  --bs-light-rgb: #{to-rgb($clr_light)};
  --bs-light: #{$clr_light};
  --bs-body-bg: #{$clr_bodyBg};
  --bs-soft-primary: color-mix(in srgb, var(--bs-primary) 15%, white) !important;
  --bs-soft-secondary: color-mix(in srgb, var(--bs-secondary) 15%, white) !important;

  // soft primary========
  .bg-soft-primary {
    background-color: var(--bs-soft-primary) !important;
    color: var(--bs-primary);

    &.cursorPointer:hover {
      background-color: var(--bs-primary) !important;
      color: $white;
    }
  }

  .border-soft-primary {
    border-color: var(--bs-soft-primary) !important;
  }

  .text-soft-primary {
    color: var(--bs-soft-primary);
  }

  // soft secondary========
  .bg-soft-secondary {
    background-color: var(--bs-soft-secondary) !important;
    color: var(--bs-secondary);

    &.cursorPointer:hover {
      background-color: var(--bs-secondary) !important;
      color: $white;
    }
  }

  .border-soft-secondary {
    border-color: var(--bs-soft-secondary) !important;
  }

  .text-soft-secondary {
    color: var(--bs-soft-secondary);
  }

}


// theme color for doctube
[data-bs-theme='doctube'] {
  @include colorTheme($clr_primary: #f15a29,
    $clr_light: #f2f4f0,
    $clr_bodyBg: #fafafa,
    $clr_secondary: #28bf61);
}

[data-bs-theme='All'] {
  @include colorTheme($clr_primary: #355fd3, $clr_light: #f0f2f4);
}

[data-bs-theme='speciality'] {
  @include colorTheme($clr_primary: #355fd3, $clr_light: #f0f2f4, $clr_bodyBg: #ffffff);
}

[data-bs-theme='Covid-19'] {
  @include colorTheme($clr_primary: #dc5353, $clr_light: #ffeeee);
}

[data-bs-theme='Allergy & Immunology'] {
  @include colorTheme($clr_primary: #6a871f, $clr_light: #f0fff0);
}

[data-bs-theme='Endocrinology'] {
  @include colorTheme($clr_primary: #574f8c, $clr_light: #f0eeff);
}

[data-bs-theme='Hematology'] {
  @include colorTheme($clr_primary: #890a07, $clr_light: #fff0f0);
}

[data-bs-theme='Nephrology'] {
  @include colorTheme($clr_primary: #6285b9, $clr_light: #f0f8ff);
}

[data-bs-theme='General Surgery'] {
  @include colorTheme($clr_primary: #a71351, $clr_light: #fff0f6);
}

[data-bs-theme='General'] {
  @include colorTheme($clr_primary: #355fd3, $clr_light: #f0f4ff);
}

[data-bs-theme='Hepatology'] {
  @include colorTheme($clr_primary: #964b00, $clr_light: #fff8ef);
}

[data-bs-theme='Pain Management'] {
  @include colorTheme($clr_primary: #8d4884, $clr_light: #ffedff);
}

[data-bs-theme='Obstetrics & Gynaecology'] {
  @include colorTheme($clr_primary: #ba1e5f, $clr_light: #fbedf2);
}

[data-bs-theme='Dermatology'] {
  @include colorTheme($clr_primary: #bc6c1b, $clr_light: #fff6ed);
}

[data-bs-theme='Pathology'] {
  @include colorTheme($clr_primary: #a932c7, $clr_light: #f7ecff);
}

[data-bs-theme='Orthopaedics'] {
  @include colorTheme($clr_primary: #b36512, $clr_light: #faf3e9);
}

[data-bs-theme='Pediatrics'] {
  @include colorTheme($clr_primary: #1278b7, $clr_light: #e5f5ff);
}

[data-bs-theme='Gastroenterology'] {
  @include colorTheme($clr_primary: #b33d00, $clr_light: #fff2ec);
}

[data-bs-theme='Urology'] {
  @include colorTheme($clr_primary: #4545e2, $clr_light: #f2f2ff);
}

[data-bs-theme='Pathology and Radiology'] {
  @include colorTheme($clr_primary: #6f52ce, $clr_light: #f5f2ff);
}

[data-bs-theme='Pulmonology'] {
  @include colorTheme($clr_primary: #37b989, $clr_light: #e8fff6);
}

[data-bs-theme='Critical Care'] {
  @include colorTheme($clr_primary: #f44141, $clr_light: #fff0f0);
}

[data-bs-theme='Rheumatology'] {
  @include colorTheme($clr_primary: #af3252, $clr_light: #fff1f5);
}

[data-bs-theme='Ophthalmology'] {
  @include colorTheme($clr_primary: #209c51, $clr_light: #e8fff1);
}

[data-bs-theme='Anesthesiology'] {
  @include colorTheme($clr_primary: #191970, $clr_light: #f0f0ff);
}

[data-bs-theme='Oncology'] {
  @include colorTheme($clr_primary: #d33f8f, $clr_light: #ffedf7);
}

[data-bs-theme='Psychiatry'] {
  @include colorTheme($clr_primary: #5d4e8b, $clr_light: #f4f0ff);
}

[data-bs-theme='Cardiology'] {
  @include colorTheme($clr_primary: #700505, $clr_light: #ffefef);
}

[data-bs-theme='Neurology'] {
  @include colorTheme($clr_primary: #9370db, $clr_light: #f6f2ff);
}

[data-bs-theme='Microbiology'] {
  @include colorTheme($clr_primary: #53975a, $clr_light: #eeffee);
}

[data-bs-theme='Internal Medicine'] {
  @include colorTheme($clr_primary: #4b24cd, $clr_light: #f5f2ff);
}

[data-bs-theme='General Medicine'] {
  @include colorTheme($clr_primary: #a71351, $clr_light: #fff0f6);
}

[data-bs-theme='Diabetology'] {
  @include colorTheme($clr_primary: #1b9381, $clr_light: #edffff);
}

[data-bs-theme='ENT'] {
  @include colorTheme($clr_primary: #1bb3b2, $clr_light: #ecffff);
}

[data-bs-theme='Onco surgery'] {
  @include colorTheme($clr_primary: #aa3b72, $clr_light: #fff0f7);
}


@each $key,
$val in $theme-colors {
  @if $key !="primary" and $key !="secondary" {
    :root {
      --bs-soft-#{$key}: color-mix(in srgb, var(--bs-#{$key}) 15%, white) !important;
    }

    .bg-soft-#{$key} {
      background-color: var(--bs-soft-#{$key}) !important;

      @if $key =="light" or $key =="white" {
        color: $black !important;
      }

      @else {
        color: $val;
      }

      &.cursorPointer:hover {
        background-color: $val !important;

        @if $key =="light" or $key =="white" {
          color: $black !important;
        }

        @else {
          color: $white !important;
        }
      }
    }

    .border-soft-#{$key} {
      border-color: var(--bs-soft-#{$key}) !important;
    }

    .text-soft-#{$key} {
      color: var(--bs-soft-#{$key}) !important;
    }
  }
}