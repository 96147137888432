@font-face {
    font-family: "flaticon_clirnet_v3_for_speciality";
src: url("./flaticon_clirnet_v3_for_speciality.ttf") format("truetype"),
        url("./flaticon_clirnet_v3_for_speciality.woff") format("woff"),
        url("./flaticon_clirnet_v3_for_speciality.woff2") format("woff2"),
        url("./flaticon_clirnet_v3_for_speciality.eot") format("embedded-opentype"),
        url("./flaticon_clirnet_v3_for_speciality.svg") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon_clirnet_v3_for_speciality !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.flaticon-heart:before {
    content: "\f101";
}
.flaticon-heart-1:before {
    content: "\f102";
}
.flaticon-oval-black-speech-bubble:before {
    content: "\f103";
}
.flaticon-share:before {
    content: "\f104";
}
.flaticon-more:before {
    content: "\f105";
}
.flaticon-more-1:before {
    content: "\f106";
}
.flaticon-layers:before {
    content: "\f107";
}
.flaticon-clock:before {
    content: "\f108";
}
.flaticon-calendar:before {
    content: "\f109";
}
.flaticon-play:before {
    content: "\f10a";
}
.flaticon-loupe:before {
    content: "\f10b";
}
.flaticon-plus-positive-add-mathematical-symbol:before {
    content: "\f10c";
}
.flaticon-minus-sign:before {
    content: "\f10d";
}
.flaticon-left-chevron:before {
    content: "\f10e";
}
.flaticon-down-arrow:before {
    content: "\f10f";
}
.flaticon-up-arrow:before {
    content: "\f110";
}
.flaticon-chevron:before {
    content: "\f111";
}
.flaticon-certificate:before {
    content: "\f112";
}
.flaticon-book:before {
    content: "\f113";
}
.flaticon-case-study:before {
    content: "\f114";
}
.flaticon-video-player:before {
    content: "\f115";
}
.flaticon-event:before {
    content: "\f116";
}
.flaticon-file:before {
    content: "\f117";
}
.flaticon-polling:before {
    content: "\f118";
}
.flaticon-facebook-app-symbol:before {
    content: "\f119";
}
.flaticon-linkedin:before {
    content: "\f11a";
}
.flaticon-youtube:before {
    content: "\f11b";
}
.flaticon-email:before {
    content: "\f11c";
}
.flaticon-phone-call:before {
    content: "\f11d";
}
.flaticon-draw:before {
    content: "\f11e";
}
.flaticon-twitter:before {
    content: "\f11f";
}
.flaticon-instagram:before {
    content: "\f120";
}
.flaticon-speaker:before {
    content: "\f121";
}
.flaticon-black-graduation-cap-tool-of-university-student-for-head:before {
    content: "\f122";
}
.flaticon-poll:before {
    content: "\f123";
}
.flaticon-target:before {
    content: "\f124";
}
.flaticon-close:before {
    content: "\f125";
}
.flaticon-notification:before {
    content: "\f126";
}
.flaticon-check:before {
    content: "\f127";
}
.flaticon-next:before {
    content: "\f128";
}
.flaticon-back:before {
    content: "\f129";
}
.flaticon-plus:before {
    content: "\f12a";
}
.flaticon-danger:before {
    content: "\f12b";
}
.flaticon-video-camera:before {
    content: "\f12c";
}
.flaticon-question:before {
    content: "\f12d";
}
.flaticon-bookmark:before {
    content: "\f12e";
}
.flaticon-save-instagram:before {
    content: "\f12f";
}
.flaticon-logout:before {
    content: "\f130";
}
.flaticon-bar-chart:before {
    content: "\f131";
}
.flaticon-pen:before {
    content: "\f132";
}
.flaticon-share-1:before {
    content: "\f133";
}
.flaticon-customer-service-headset:before {
    content: "\f134";
}
.flaticon-volume-mute:before {
    content: "\f135";
}
.flaticon-volume:before {
    content: "\f136";
}
.flaticon-pause-button:before {
    content: "\f137";
}
.flaticon-play-and-pause-button:before {
    content: "\f138";
}
.flaticon-bubble-chat:before {
    content: "\f139";
}
.flaticon-filter-filled-tool-symbol:before {
    content: "\f13a";
}
.flaticon-add-friend:before {
    content: "\f13b";
}
.flaticon-star:before {
    content: "\f13c";
}
.flaticon-send:before {
    content: "\f13d";
}
.flaticon-verify:before {
    content: "\f13e";
}
.flaticon-check-1:before {
    content: "\f13f";
}
.flaticon-graph-bar:before {
    content: "\f140";
}
.flaticon-cloud-upload:before {
    content: "\f141";
}
.flaticon-add-to-playlist:before {
    content: "\f142";
}
.flaticon-whatsapp:before {
    content: "\f143";
}
.flaticon-add-group:before {
    content: "\f144";
}
.flaticon-play-1:before {
    content: "\f145";
}
.flaticon-man:before {
    content: "\f146";
}
.flaticon-woman:before {
    content: "\f147";
}
.flaticon-undo:before {
    content: "\f148";
}
.flaticon-zoom-in:before {
    content: "\f149";
}
.flaticon-zoom-in-1:before {
    content: "\f14a";
}
.flaticon-clock-1:before {
    content: "\f14b";
}
.flaticon-badge:before {
    content: "\f14c";
}
.flaticon-attach-paperclip-symbol:before {
    content: "\f14d";
}
.flaticon-stethoscope:before {
    content: "\f14e";
}
.flaticon-checked:before {
    content: "\f14f";
}
.flaticon-download:before {
    content: "\f150";
}
.flaticon-upload:before {
    content: "\f151";
}
.flaticon-add-user:before {
    content: "\f152";
}
.flaticon-video:before {
    content: "\f153";
}
.flaticon-camera:before {
    content: "\f154";
}
.flaticon-channel:before {
    content: "\f155";
}
.flaticon-clinicalvideo:before {
    content: "\f156";
}
.flaticon-discussandrefer:before {
    content: "\f157";
}
.flaticon-ebook:before {
    content: "\f158";
}
.flaticon-grandround:before {
    content: "\f159";
}
.flaticon-home-speciality:before {
    content: "\f15a";
}
.flaticon-icon-material-group:before {
    content: "\f15b";
}
.flaticon-livecme:before {
    content: "\f15c";
}
.flaticon-ddx:before {
    content: "\f15d";
}
.flaticon-polls:before {
    content: "\f15e";
}
.flaticon-telemed:before {
    content: "\f15f";
}
.flaticon-doctube:before {
    content: "\f160";
}
.flaticon-medwiki:before {
    content: "\f161";
}
.flaticon-training:before {
    content: "\f162";
}
.flaticon-trophy:before {
    content: "\f163";
}
.flaticon-pin:before {
    content: "\f164";
}
.flaticon-lock-symbol:before {
    content: "\f165";
}
.flaticon-certificate-1:before {
    content: "\f166";
}
.flaticon-user:before {
    content: "\f167";
}
.flaticon-pie-graph:before {
    content: "\f168";
}
.flaticon-home:before {
    content: "\f169";
}
.flaticon-camera-1:before {
    content: "\f16a";
}
.flaticon-hospital:before {
    content: "\f16b";
}
.flaticon-add-button:before {
    content: "\f16c";
}
.flaticon-correct:before {
    content: "\f16d";
}
.flaticon-add-contact:before {
    content: "\f16e";
}
.flaticon-ad-sponsor:before {
    content: "\f16f";
}
.flaticon-delete:before {
    content: "\f170";
}
.flaticon-other:before {
    content: "\f171";
}
.flaticon-like:before {
    content: "\f172";
}
.flaticon-like-1:before {
    content: "\f173";
}
.flaticon-playlist:before {
    content: "\f174";
}
.flaticon-calendar-1:before {
    content: "\f175";
}
.flaticon-checklist:before {
    content: "\f176";
}
.flaticon-up-arrow-1:before {
    content: "\f177";
}
.flaticon-star-1:before {
    content: "\f178";
}
.flaticon-transfer-data:before {
    content: "\f179";
}
.flaticon-eye:before {
    content: "\f17a";
}
.flaticon-group:before {
    content: "\f17b";
}
.flaticon-user-1:before {
    content: "\f17c";
}
.flaticon-play-2:before {
    content: "\f17d";
}
.flaticon-show:before {
    content: "\f17e";
}
.flaticon-drag:before {
    content: "\f17f";
}
.flaticon-save:before {
    content: "\f180";
}
.flaticon-remove-file:before {
    content: "\f181";
}
.flaticon-sad:before {
    content: "\f182";
}
.flaticon-red-flag:before {
    content: "\f183";
}
