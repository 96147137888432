@import '../custom';
.speciality-portal {
  .desktopBodyIn {
    background-color: #fafafa;
    .container-fluid {
      padding: 0;
    }
    @include media-breakpoint-down(md) {
      padding: 1rem !important;
    }
  }

  // search page
  .searchResultV2 .searchTabSection .searchTabs {
    top: 7.5rem;
    @include media-breakpoint-down(md) {
      top: 0;
    }
  }
}
