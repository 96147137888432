$input-btn-padding-y-lg: 1.8rem;
$input-btn-padding-x-lg: 2.2rem;
$input-btn-padding-y-sm: 1rem;
$input-btn-padding-x-sm: 1.2rem;
$input-btn-padding-y: 1.4rem;
$input-btn-padding-x: 1.7rem;
$input-btn-focus-box-shadow: none;
$input-btn-font-size: 1.4rem;
$input-border-width: 0.2rem;
$input-border-radius: 1rem;
$form-floating-height: 5.6rem;
$form-floating-line-height: 1.25;
$form-floating-padding-y: 1.6rem;
$form-floating-input-padding-t: 0;
$form-floating-input-padding-b: 0;
$form-floating-label-opacity: 1;
$form-floating-label-transform: scale(0.8) translateY(-1.3rem) translateX(0);
$form-floating-transition: all 0.15s ease-in-out;

$input-focus-box-shadow: none !important;
$form-check-input-focus-box-shadow: none !important;
$form-select-focus-box-shadow: none !important;
$input-bg: #ffffff;
$input-box-shadow: null !important;
$input-focus-border-color: null !important;
