@mixin clr_v3_userImage($w: 3.2rem, $w-sm: 3.6rem) {
    width: $w;
    height: $w;

    @include media-breakpoint-up(sm) {
        width: $w-sm;
        height: $w-sm;
    }
}

.clr_v3_userImage {
    @include clr_v3_userImage();
}

.clr_v3_userImage-sm {
    @include clr_v3_userImage($w: 4rem, $w-sm: 4.4rem);
}

.clr_v3_userImage-lg {
    @include clr_v3_userImage($w: 7rem, $w-sm: 8.2rem);
}