@import '../../../assets/sass/custom';

//app tour css
.takeTourModal {
  .modal-content {
    border: none;
    border-radius: $border-radius-lg;
  }

  .modal-body {
    padding: 4rem;
    overflow: hidden;

    .cmnBtn {
      padding: 1.8rem 2.5rem;
    }
  }

  .introPopLeft {
    position: relative;
    width: 36rem;
    max-width: 100%;
  }

  .introDoc {
    position: relative;
    z-index: 1;
    width: 100%;
    height: auto;
    // max-height: 38rem;
  }

  .introPopAnimation {
    width: 15rem;
    height: 15rem;
    border-radius: 50%;
    background-color: rgba(86, 96, 211, 0.12);
    position: absolute;
    left: 2%;
    bottom: 6%;
  }

  .introPopAnimation2 {
    width: 30rem;
    height: 30rem;
    background-color: rgba(52, 202, 245, 0.14);
    left: auto;
    right: 5%;
    bottom: 10%;
  }
}

.tourContent_WithIcon {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding-left: 9rem;
  min-height: 6.4rem;
}

.appTourIcon {
  position: absolute;
  top: 0;
  left: 0;
  svg {
    max-width: 3rem;
    max-height: 3rem;
    fill: white;
  }

  &.text-primary {
    background-color: var(--bs-primary);

    &::after,
    &::before {
      border-color: var(--bs-primary);
    }
  }

  &.colorGreen {
    background-color: var(--bs-secondary);

    &::after,
    &::before {
      border-color: var(--bs-secondary);
    }
  }
  &.primary {
    background-color: var(--bs-primary);

    &::after,
    &::before {
      border-color: var(--bs-primary);
    }
  }

  &.colorSkyBlue {
    background-color: var(--bs-secondary);

    &::after,
    &::before {
      border-color: var(--bs-secondary);
    }
  }

  &.colorYellow {
    background-color: $warning;

    &::after,
    &::before {
      border-color: $warning;
    }
  }

  &.text-purple {
    background-color: $purple;

    &::after,
    &::before {
      border-color: $purple;
    }
  }
}

.react-joyride__tooltip {
  button {
    width: auto !important;
    min-width: 3.6rem !important;
    @include cmnTransition;

    svg {
      @include cmnTransition;
    }

    span {
      display: inline-flex;
      align-items: center;
      margin-top: 2px;
      @include cmnTransition($time: 0.1s);

      svg {
        margin-top: -2px;
      }
    }

    &:hover {
      color: $white !important;
      background-color: var(--bs-primary) !important;

      svg {
        fill: $white;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .takeTourModal {
    .introPopLeft {
      width: 30rem;
    }

    .modal-body {
      padding: 3rem 1.8rem;

      .font_30 {
        font-size: $h1-font-size;
      }
    }

    .introDoc {
      // max-height: 30rem;
    }

    .introPopAnimation2 {
      bottom: 5%;
      height: 26rem;
      left: auto;
      right: 4%;
      width: 26rem;
    }
  }

  .appTourIcon {
    width: 5rem;
    height: 5rem;

    &::before {
      width: 7.4rem;
      height: 7.4rem;
      top: -1.2rem;
      left: -1.2rem;
    }

    &::after {
      width: 6.4rem;
      height: 6.4rem;
      top: -0.7rem;
      left: -0.7rem;
    }
  }

  .tourContent_WithIcon {
    padding-left: 6.5rem;
    min-height: 5rem;
  }
}
