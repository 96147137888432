@import '../../../assets/sass/custom';

$srchWidth: 4.8rem;

.deskHdrSrchBarArea {
  min-width: $srchWidth;
  min-height: $srchWidth;
}

.deskHdrSrchBar {
  background-color: #e8e8e8;
  width: $srchWidth;
  height: $srchWidth;
  padding-right: $srchWidth;
  @include cmnTransition;

  .deskHdrSrchInput {
    width: 0;
    height: $srchWidth;
    @include cmnTransition;
  }

  .deskHdrSrchIcon,
  .searchSubmiBtn {
    width: $srchWidth;
    height: $srchWidth;
    @include cmnTransition;

    &:hover {
      background-color: $light;
      color: $white;
    }
  }

  .searchSubmiBtn {
    display: none !important;

    svg {
      opacity: 0;
    }
  }

  .searchSuggestion {
    top: calc(100% + ((7rem - 4.8rem) / 2));
    // top: 4.8rem;
    box-shadow: 0.2rem 1.4rem 1.5rem 0 rgba(0, 0, 0, 0.1);
    z-index: 9;
    border-radius: 0 0 1.2rem 1.2rem;
    max-height: 55vh;

    ul {
      &:last-child {
        border-bottom: none !important;

        li:last-child {
          border-radius: 0 0 1.2rem 1.2rem;
        }
      }

      li {
        @include cmnTransition($time: 0.1s);

        a {
          @include cmnTransition($time: 0.1s);
        }

        .main_keyWord {
          a {
            padding-right: 12rem;
            padding-left: 4.5rem;
            // padding-left: 2rem;

            i {
              left: 1.5rem;
            }

            .searchThumbnail {
              width: 8rem;
              height: 4.5rem;
              right: 1.5rem;
            }

            &:hover {
              color: $black !important;
            }
          }
        }

        .s_remove {
          position: absolute;
          right: 1.5rem;
          @include translateY;
          z-index: 1;
          height: 100%;
          @include cmnTransition($time: 0.1s);

          a:hover {
            color: $danger;
          }
        }

        &:hover {
          background-color: #f3f3f3;

          .s_remove {
            background-color: #f3f3f3;
          }
        }
      }
    }

    .loader-line {
      height: 3px;
      background-color: #ddd;
      border-radius: 20px;
    }

    .loader-line:before {
      content: '';
      position: absolute;
      left: -50%;
      height: 3px;
      width: 40%;
      background-color: var(--bs-secondary);
      -webkit-animation: lineAnim 1s linear infinite;
      -moz-animation: lineAnim 1s linear infinite;
      animation: lineAnim 1s linear infinite;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
      border-radius: 20px;
    }

    @keyframes lineAnim {
      0% {
        left: -40%;
      }

      50% {
        left: 20%;
        width: 80%;
      }

      100% {
        left: 100%;
        width: 100%;
      }
    }

    .searchSuggsnTopic {
      box-shadow: 0 0.2rem 0.7rem 0 rgba(0, 0, 0, 0.1);
    }
  }

  &.open {
    width: 60rem;

    .searchSuggestion {
      display: block !important;
    }

    .deskHdrSrchIcon {
      display: none !important;
    }

    .deskHdrSrchInput {
      width: 100%;
      padding: 0 2rem !important;
    }

    .searchSubmiBtn {
      display: block !important;

      svg {
        opacity: 1;
      }
    }
  }

  .searchColse {
    width: 5rem;
  }
}

@media screen and (max-width: 991px) {
  .mblNav {
    position: relative;
  }

  .mobileSearch {
    background-color: darken($primary, 5%) !important;
    transition: none;

    .deskHdrSrchInput {
      height: 100%;
      color: $white;
      transition: none;
    }

    // .deskHdrSrchIcon svg {
    //   color: $white;
    //   fill: $white;
    // }

    .searchSubmiBtn {
      border-radius: 0 !important;
      background-color: rgba(0, 0, 0, 0.15);
      color: $white !important;
      width: 6rem;
      height: 100%;

      svg {
        fill: $white;
        color: $white;
      }
    }

    &.open {
      width: 100vw;
      height: 6rem;
      @include translateX;
      position: absolute !important;
      border-radius: 0 !important;
      top: 0;
      padding: 0 6rem 0 5rem;

      .searchColse {
        display: flex !important;
      }

      .searchSuggestion {
        top: 100%;
        max-height: none;
        height: calc(100vh - 6rem);
      }

      .deskHdrSrchInput {
        padding: 0 0.8rem;
      }

      .searchSubmiBtn.text-black {
        color: $white;
      }

      .deskHdrSrchInput::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #9caddc;
        opacity: 1;
        /* Firefox */
      }
    }
  }
}