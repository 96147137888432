@import '../../../assets/sass/custom';

.deskHeader {
  height: 9rem;
  @include cmnTransition;

  &:before {
    position: absolute;
    top: 0;
    height: 2rem;
    width: 100%;
    background-color: $black;
    content: '';
    left: 0;
  }

  .deskHeaderTop {
    @include cmnTransition;
    padding-left: 8rem;
  }

  .dskMarquee {
    height: 2rem;

    .overlay {
      display: none;
    }
  }

  .navbar-brand {
    height: 7rem;

    .topLogo {
      width: 6.6rem;
      height: 6.6rem;
      @include cmnTransition($time: 0.12s);

      svg,
      img {
        @include cmnTransition($time: 0.12s);

        &.topLogo1 {
          height: 4.4rem;
          transform: translate(-50%, -50%) scale(1);
        }

        &.topLogo2 {
          opacity: 0;
          height: 5rem;
          transform: translate(-50%, -50%) scale(0);
        }
      }
    }
  }

  .deskMainHeader {
    height: 7rem;
  }

  .deskTpNav_left {
    .deskHdrSrch {
      cursor: pointer;
      float: left;
      height: 5rem;
      width: 5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      // @include displayProp($justifyContent: center);
      margin-left: 2.5rem;
    }

    .deskMenuToggle {
      width: 3.6rem;
      height: 2.4rem;
      transform: rotate(-90deg);
      @include cmnTransition($time: 0.12s);

      &:focus {
        box-shadow: none;
      }

      .navbar-toggler-icon {
        width: 2.8rem;
        height: 2px;
        background-image: none;
        transform: translate(-50%, -50%) rotate(-45deg);
        @include cmnTransition;

        &::before,
        &::after {
          @include cmnTransition;
          position: absolute;
          background-color: $black;
          content: '';
          width: 100%;
          height: 2px;
          left: 0px;
        }

        &::before {
          opacity: 0;
          top: 1.1rem;
        }

        &::after {
          width: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }

      &.collapsed {
        transform: rotate(0);

        .navbar-toggler-icon {
          transform: translate(0) rotate(0);
          top: 0 !important;
          left: 0px !important;
          width: 100%;

          &::after {
            transform: translate(0) rotate(0);
            top: 2.2rem;
            left: 0px;
            width: 2.2rem;
          }

          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  .deskTpNav_Right {
    .deskNavProfile {
      .deskNavProfilePic {
        width: 4rem;
        height: 4rem;
        // background-color: darken(var(--bs-primary), 10%);
      }

      .deskNavProfileTxt {
        line-height: 1.2;

        span {
          display: block;
        }
      }
    }

    .headerPoints {
      padding: 0 1rem;
      height: 4.4rem;
      margin: 0 1rem;
      position: relative;
      overflow: hidden;
      color: $white;

      h2 {
        min-width: 8rem;
      }

      &::before,
      &::after {
        position: absolute;
        top: 0;
        background-color: rgba(0, 0, 0, 0.1);
        content: '';
        position: absolute;
        clip-path: polygon(22% 0, 0 100%, 100% 0);
      }

      &::before {
        right: -2.5rem;
        width: 6rem;
        height: 4rem;
      }

      &::after {
        right: 0.5rem;
        width: 4rem;
        height: 3rem;
      }
    }

    .deskNavRight {
      .dskTakeTourBtn {
        background-color: #f8f8f8;

        &:hover {
          background-color: $light;
        }
      }
            .header_desktop_takeTour_icon {
              width: 2.5rem;
              height: 2.5rem;
            }

      .dskNotificationBtn {
        .notificationCount {
          width: 2.5rem;
          height: 2.5rem;
          top: -1.5rem;
          right: -0.5rem;
        }
      }

      .nav-link {
        @include cmnTransition;

        &.deskNavVault {
          position: relative;
          background-color: rgba(53, 95, 211, 0.3);
          padding: 1rem 1.5rem;
          margin-right: 2rem;

          span {
            position: absolute;
            top: -0.8rem;
            right: -0.8rem;
            height: 2.4rem;
            min-width: 2.4rem;
            background-color: var(--bs-primary);
            color: $white;
            text-align: center;
            padding: 0.3rem;
            display: flex;
            justify-content: center;
            align-items: center;
            // @include displayProp($justifyContent: center);
          }

          &:hover {
            background-color: var(--bs-primary);
            color: $white;
          }
        }

        &.headerPoints:hover {
          color: $white;
          background-color: var(--bs-secondary);
        }
      }
    }
  }

  .deskNavDots {
    .dropdown-toggle {
      @include displayProp($justifyContent: center);
      color: $black;
      width: 4.4rem;
      height: 4.4rem;
      border-radius: $border-radius;

      &:after {
        display: none;
      }

      &:hover {
        background-color: #e3e8eb;
      }
    }

    .dropdown-menu {
      position: absolute;
      right: 0;
      left: auto;
      width: 18rem;
      top: calc(100% + 2rem);
      margin: 0;
      box-shadow: $box-shadow;
      border: none;
      border-radius: $border-radius;
      background-color: $white;
    }

    .dropdown-item {
      padding-left: 2rem !important;

      svg {
        left: 1rem;
      }

      &.deskNavVault {
        span {
          height: 2.2rem;
          min-width: 2.4rem;
        }
      }

      &:hover {
        background-color: #e3e8eb;
      }
    }
  }
}

.deskLeftmenuBar {
  top: 2rem;
  height: calc(100vh - 2rem);
  width: 8rem;
  @include cmnTransition;

  .deskNavMenu {
    .nav-link.deskMunuLink {
      height: 5.6rem;
      padding-left: 8rem;

      &:last-child {
        margin-bottom: 0 !important;
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 100%;
        content: '';
        background-color: #e7eef1;
        @include cmnTransition($time: 0.15s);
      }

      &::after {
        width: 10px;
        left: 0;
        top: 0;
        height: 100%;
        clip-path: polygon(0 0, 100% 50%, 0% 100%);
        @include cmnTransition($time: 0.15s);
        content: '';
        position: absolute;
        opacity: 0;
        background-color: #e7eef1;
      }

      .deskMunuLinkIcon {
        i {
          font-size: 1.8rem;
        }

        width: 8rem;
        height: 3rem;
        top: 0.5rem;
        @include cmnTransition;

        .deskTopmenuSVG {
          width: 3.2rem;
          height: 2.2rem;
          filter: grayscale(1) brightness(1);
          color: #6c6c6c;
          fill: #6c6c6c;
          @include cmnTransition;
        }

        // .deskTopmenuSVGdiagnosis {
        //   width: 5rem;
        // }
      }

      .deskMunuLinkTxt {
        width: 8rem;
        @include cmnTransition($time: 0.1s);
        color: #6c6c6c;
        font-size: 1.25rem;
        bottom: 0.4rem;
      }

      .deskMunuLinkTxt2 {
        font-size: 0;
        opacity: 0;
        @include cmnTransition;
        transform: translateX(-2rem);
        color: #6c6c6c;
      }

      &:hover {
        width: 23.5rem;

        &::after {
          opacity: 1;
          left: 100%;
        }

        &::before {
          width: 100%;
        }

        .deskMunuLinkTxt2 {
          opacity: 1;
          transform: translateX(0);
          font-size: $h4-font-size;
          color: var(--bs-primary);
        }

        .deskMunuLinkTxt {
          font-size: 0;
          transform: translateX(-50%) scale(0);
          opacity: 0;
        }

        .deskMunuLinkIcon {
          height: 100%;
          top: 0;

          .deskTopmenuSVG {
            color: var(--bs-primary);
            fill: var(--bs-primary);
            filter: grayscale(0) brightness(1);
          }
        }
      }

      &.active {
        &::after {
          opacity: 1;
          // left: 100%;
          left: 8rem;
        }

        &::before {
          // width: 100%;
          width: 8rem;
        }

        &:hover {
          &::after {
            left: 100%;
          }

          &::before {
            width: 100%;
          }
        }

        .deskMunuLinkTxt,
        .deskMunuLinkTxt2 {
          color: var(--bs-primary);
        }

        .deskMunuLinkIcon {
          .deskTopmenuSVG {
            color: var(--bs-primary);
            fill: var(--bs-primary);
            filter: grayscale(0) brightness(1);
          }
        }
      }
    }
  }
}

.menuShow {
  .deskHeader {
    .deskHeaderTop {
      padding-left: 23.5rem;
    }

    .deskLeftmenuBar {
      width: 23.5rem;

      .navbar-brand {
        .topLogo {
          width: 19rem;

          svg,
          img {
            &.topLogo2 {
              opacity: 1;
              transform: translate(-50%, -50%) scale(1);
            }

            &.topLogo1 {
              opacity: 0;
              transform: translate(-50%, -50%) scale(0);
            }
          }
        }
      }

      .deskNavMenu {
        .nav-link.deskMunuLink {
          .deskMunuLinkTxt2 {
            opacity: 1;
            transform: translateX(0);
            font-size: $h4-font-size;
          }

          .deskMunuLinkTxt {
            font-size: 0;
            transform: translateX(-50%) scale(0);
            opacity: 0;
          }

          .deskMunuLinkIcon {
            height: 100%;
            top: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1499px) {
}

// @media screen and (max-width:1199px) {
//     .deskHeader{ height: 7rem; padding-left: 7rem;
//         .deskMainHeader{height: 5.5rem; padding: 0 1.5rem;}
//         .deskTpNav_Right .deskNavProfile .deskNavProfilePic{ width: 4.4rem; height: 4.4rem;}
//         .dskMarquee{ height: 1.5rem; line-height: 1.5rem;}
//         .deskLeftmenuBar{ width: 7rem;
//             .navbar-brand{ height: 7rem;}
//             .deskNavMenu{ height: calc(100vh - 7rem);
//                 .nav-link.deskMunuLink{ padding-left: 7rem; min-height: 4rem;
//                     .deskMunuLinkIcon{ width: 7rem;
//                         img{ max-height: 1.9rem;  max-width: 1.9rem;}
//                     }
//                     &:hover{ width: 19.5rem;}
//                 }
//             }

//         }
//     }

// }

.deskNavMenuSlider {
  width: calc(100% + 1.5rem);

  &.deskMunuLinkHover {
    width: 25rem;
  }

  // &.swiper-autoheight {
  //   .swiper-slide {
  //     max-height: 5.6rem !important;
  //   }
  // }
  .swiper-button-next {
    bottom: 0;
    left: 0;
    top: auto;
    right: auto;
    width: 8rem;
    height: 2.5rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.8018557764902836) 65%,
      rgba(255, 255, 255, 0) 100%
    );

    &::after {
      font-size: 1.6rem;
      color: var(--bs-primary);
      transform: rotate(90deg);
    }

    &.swiper-button-disabled {
      display: none;
    }
  }

  .swiper-button-prev {
    left: 0;
    top: 2.5rem;
    bottom: auto;
    width: 8rem;
    height: 2.5rem;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.8018557764902836) 65%,
      rgba(255, 255, 255, 0) 100%
    );

    &::after {
      font-size: 1.6rem;
      color: var(--bs-primary);
      transform: rotate(90deg);
    }

    &.swiper-button-disabled {
      display: none;
    }
  }
}

.deskLeftmenuBar {
  &.fullMenuShow {
    .deskNavMenuSlider {
      .swiper-button-next,
      .swiper-button-prev {
        width: calc(100% - 1.5rem);
      }
    }

    .deskNavMenu .nav-link.deskMunuLink.active {
      &::after {
        opacity: 1;
        left: calc(100% - 1.5rem);
      }

      &::before {
        width: calc(100% - 1.5rem);
      }

      &:hover {
        &::after {
          left: 100%;
        }

        &::before {
          width: 100%;
        }
      }
    }
  }
}
