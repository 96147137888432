@import '../custom';

.btn {
  border-width: 0.15rem;
  font-weight: 500;
  @include rippleEffect;


  &:focus-visible {
    box-shadow: none !important;
  }
}

@each $color,
$value in $theme-colors {
  .btn-#{$color} {
    @if $color =='light' or $color =='white' {
      @include button-variant($value,
        $value,
        $hover-background: transparent,
        $hover-border: $value,
        $active-background: $value,
        $active-border: $value,
        $hover-color: $value );
    }

    @else if $color =='dark' or $color =='black' {
      @include button-variant($value,
        $value,
        $hover-background: transparent,
        $hover-color: $value,
        $hover-border: $value,
        $active-background: $value,
        $active-border: $value );
    }

    @else {
      --bs-btn-color: #ffffff !important;
      --bs-btn-bg: var(--bs-#{$color}) !important;
      --bs-btn-border-color: var(--bs-#{$color}) !important;
      --bs-btn-hover-color: var(--bs-#{$color}) !important;
      --bs-btn-hover-bg: transparent !important;
      --bs-btn-hover-border-color: var(--bs-#{$color}) !important;
      --bs-btn-focus-shadow-rgb: none !important;
      --bs-btn-active-color: #ffffff !important;
      --bs-btn-active-bg: var(--bs-#{$color}) !important;
      --bs-btn-active-border-color: var(--bs-#{$color}) !important;

      --bs-btn-disabled-color: #ffffff !important;
      --bs-btn-disabled-bg: var(--bs-#{$color}) !important;
      --bs-btn-disabled-border-color: var(--bs-#{$color}) !important;
    }
  }
}

@each $color,
$value in $theme-colors {
  .btn-outline-#{$color} {
    --bs-btn-color: var(--bs-#{$color}) !important;
    --bs-btn-border-color: var(--bs-#{$color}) !important;
    --bs-btn-hover-bg: var(--bs-#{$color}) !important;
    --bs-btn-hover-border-color: var(--bs-#{$color}) !important;
    --bs-btn-active-color: var(--bs-#{$color}) !important;
    --bs-btn-active-bg: #ffffff !important;
    --bs-btn-active-border-color: var(--bs-#{$color}) !important;
    --bs-btn-disabled-color: var(--bs-#{$color}) !important;
    --bs-btn-disabled-bg: transparent !important;
    --bs-btn-disabled-border-color: var(--bs-#{$color}) !important;

    &::after {
      background-color: rgba($white, .8) !important;
    }
  }

  @if $color =='light' or $color =='white' {
    .btn-outline-#{$color} {
      --bs-btn-hover-color: #080808 !important;
    }
  }

  @else {
    .btn-outline-#{$color} {
      --bs-btn-hover-color: white !important;
    }
  }
}

.clr_v3_icon-btn-1:hover {
  background-color: rgba($black, .08);
}