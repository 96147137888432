// @import "./variables/index";
$white: #ffffff;
$black: #161616;
$green: #1fcb9d;
$yellow: #e8c11f;
$red: #d82650;
$orange: #f15a29;
$gray-300: #e5e5e5;
$gray-600: #525252;
$extra-light: #f8f9fa;
$dark: $gray-600;
$purple: #6715cd;
$primary: #355fd3;
$secondary: #1fcb9d;
$light: $gray-300;
$body-color: $gray-600;
$body-bg: #f0f0f0;
$gray-extra-light: $extra-light;

// box-shadow
$box-shadow: 0.2rem 0.2rem 2.8rem 0 rgba($black, 0.12);

// $font-size-root: 62.5% !important;

$font-size-base: 1rem;
$link-decoration: none;
$enable-negative-margins: true;
$font-family-sans-serif: 'Poppins', sans-serif;


$table-bg: transparent;

//========================heading h1 h2 h3 h4 h5 h6
$headings-margin-bottom: 0;

$border-radius: 0.6rem;
$border-radius-sm: 0.4rem;
$border-radius-lg: 1rem;
$border-radius-xl: 1.5rem;
$border-radius-circle: 100%;

$h1-font-size: $font-size-base * 2.4;
$h3-font-size: $font-size-base * 1.8;
$h5-font-size: $font-size-base * 1.2;
$h4-font-size: $font-size-base * 1.4;

$transition-base: all 0.2s ease-in-out;