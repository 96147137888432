// --bs-modal-bg: #F00;
// $color-mode-type: data;


.modal {
    @include color-mode(dark) {
        .modal-content {
            background-color: $dark;
            color: $white !important;
        }

        .text-black,
        .text-dark,
        label {
            color: $white !important;
        }
    }
}